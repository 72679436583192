<template>
    <div class="contributors-wrapper" v-if="this.expertdata.attributes">
        <div class="tile">
            <div class="fields">
                <div v-for="(image, index) in this.expertFields" 
                    :key="'img'+index" class="field" 
                    :style="fieldStyle(image.attributes.drupal_internal__tid)">
                </div>
            </div>
            <div class="info">
                <div class="profile">
                    <images :imagedata="this.expertdata" :fieldname="'field_expert_photo'" :breakstyles="{'0': 'xs_image', '576': 'sm_image'}"></images>
                </div>
            </div>
            <div class="content">
                <h4 class="name">{{ this.expertdata.attributes.field_name + " " + this.expertdata.attributes.field_surname }}</h4>
                <p>{{ this.expertdata.attributes.field_expert_professione }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { fieldColorExpert, fieldSrcExpert } from '../libs/utils.js'
import Images from './Images.vue'
export default {
    name: 'expert-tile-min',
    data: () => {
        return {
        }
    },
    props: {
        expertdata: Object,
        filtersdata: Array
    },
    components: {
        Images
    },
    computed: {
        profilephoto() {
            return this.expertdata.field_expert_photo ? (process.env.VUE_APP_ENDPOINT + this.expertdata.field_expert_photo.attributes.uri.url) : ""
        },
        expertFields() {
            var array = []
            if(this.expertdata.field_expert_ambito)
                this.expertdata.field_expert_ambito.forEach(f => {
                    var find = this.filtersdata.find(ff => f.attributes.name.toLowerCase() == ff.attributes.name.toLowerCase())
                    if(find)
                        array.push(find)
                })
            return array
        }
    },
    methods: {
         fieldStyle(image) {
            var f = this.filtersdata.find(i => i.attributes.drupal_internal__tid==image)
            var n = f ? f.attributes.name : 'not-found'

            return '--background-color: ' + fieldColorExpert(image) + ';' +
                '--background-image: url(' + fieldSrcExpert(image) + ');' +
                '--field-text: "' + n + '";' + 
                '--field-width: ' + n.length*7 + 'px;'
        }
    }, 
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
</style>